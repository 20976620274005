/* Für WebKit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px;  /* Breite der vertikalen Scrollbar */
    height: 8px;  /* Höhe der horizontalen Scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(185, 185, 185, 0.5);  /* Farbe des Scrollbar-Schiebereglers */
    border-radius: 4px;  /* Rundung des Schiebereglers */
}

::-webkit-scrollbar-track {
    background: transparent;  /* Hintergrundfarbe des Scrollbar-Tracks (setzen Sie es auf transparent, um keinen Hintergrund zu haben) */
}

/* Verstecke die Scrollbar im Ruhemodus */
body {
    scrollbar-width: none;
}

/* Zeige die Scrollbar beim Hover */
body:hover {
    scrollbar-width: thin;
}